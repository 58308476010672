<!-- 销售站点主管账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-input placeholder="名称/电话/联系人" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:200px;" @input="curr=1;getList()"></el-input>
            <!-- <el-input placeholder="电话" v-model.trim="select.mobile" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;" @input="curr=1;getList()"></el-input>
            <el-input placeholder="联系人" v-model.trim="select.contacts" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;" @input="curr=1;getList()"></el-input> -->
            <el-select v-model="select.sales_id" placeholder="业务员" clearable style="width:140px" @change="curr=1;getList()">
                <el-option v-for="(item,index) in yewu" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
            <el-date-picker style="width:230px" v-model="select.create" type="daterange" range-separator="至" start-placeholder="生日时间" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
            </el-date-picker>
            <div class="block" style="width:200px">
                <el-cascader v-model="tableAddress" :options="addressOptions" :props="{ expandTrigger: 'hover' }" @change="addressHandleChange" placeholder="省/市/区" :clearable="true"></el-cascader>
            </div>
            <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user_type_name" label="分类" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="contacts" label="联系人" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="mobile" label="电话" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="birthday" label="生日" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="site.name" label="销售站点" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="sales.name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="省市区" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.province+scope.row.city+scope.row.district}}
                </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column v-if="isAdmin" prop="msg_left" label="剩余短信条数" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="is_remind" label="是否提醒生日祝福" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.is_remind==1?'是':'否'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="300px">
                <template slot-scope="scope">
                    <el-button plain type="success" size="mini" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="mini" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                    <el-button plain type="warning" size="mini" @click="handleDet(scope.row.id)">统计</el-button>
                    <el-button v-if="isAdmin" plain type="primary" size="mini" @click="handleSet(scope.row.id)">设置短信条数</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            addressOptions: [],
            tableAddress: [],
            yewu: [],
            isAdmin: false
        }
    },
    mounted: function() {
        this.getList();
        if(sessionStorage.getItem('bureau_id')) {
            this.isAdmin = false;
        } else {
            this.isAdmin = true;
        }
        this.paperQueryAddress()
    },
    methods: {
        paperQueryAddress() {
            this.http.post('/admin.area/getList', {}, false).then(re => {
                this.addressOptions = re.data
            })

            this.http.post('/admin.admin/getSalesList', {}, false).then(re => {
                this.yewu = re.data
            })
        },
        async addressHandleChange() {
            this.curr = 1;
            await this.getList();
        },
        // 跳转统计
        handleDet(id) {

            if(this.isAdmin == false) {
                this.$router.push({
                    path: '/subCustomerDet',
                    query: {
                        id: id,
                    }
                });
            } else {
                this.$router.push({
                    path: '/customerDetail',
                    query: {
                        id: id,
                    }
                });
            }
        },
        handleSet(id) {
            this.$prompt(' ', '设置短信条数', {
                inputPlaceholder: '请填写正整数',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^[1-9]\d*$/,
                inputErrorMessage: '格式不对'
            }).then(({
                value
            }) => {
                this.http.post('/admin.userMsg/save', {
                    num: Number(value),
                    user_id: id,
                }).then(() => {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '设置成功!'
                    });
                })
            }).catch(() => {});
        },
        getList: function() {
            const adcode = this.tableAddress.length > 0 ? this.tableAddress[this.tableAddress.length - 1] : ''
            this.http.post('/admin.user/getList', {
                curr: this.curr,
                row: this.row,
                // name: this.select.name,
                // mobile: this.select.mobile,
                // contacts: this.select.contacts,
                create: this.select.create,
                adcode,
                sales_id: this.select.sales_id,
                search: this.searchValue
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },
        edit: function(row) {
            if(this.isAdmin == false) {
                this.$router.push({
                    path: '/subCustomerAdd',
                    query: {
                        id: row ? Number(row.id) : 'new',
                    }
                });
            } else {
                this.$router.push({
                    path: '/customerAdd',
                    query: {
                        id: row ? Number(row.id) : 'new',
                    }
                });
            }
        },
        handleDel(id) {
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.user/delete', {
                    id: id
                }).then(() => {
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
                })
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
